import React from "react";
import Banner from "../Banner";

function CertificateBanner(){
    return (
        <div>
        <Banner 
            bannertext="CERTIFICATES" 
        />
        </div>
    );
}

export default CertificateBanner;