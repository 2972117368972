import React from "react";
import Banner from "../Banner";

function Class10Banner(){
    return (
        <div>
        <Banner 
            bannertext="CLASS-10" 
        />
        </div>
    );
}

export default Class10Banner;