import React from "react";
import Banner from "../Banner";

function BadgeBanner(){
    return (
        <div>
        <Banner 
            bannertext="BADGES" 
        />
        </div>
    );
}

export default BadgeBanner;