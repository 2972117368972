import React from "react";
import Banner from "../Banner";

function MiscellaneousBanner(){
    return (
        <div>
        <Banner 
            bannertext="MISCELLANEOUS" 
        />
        </div>
    );
}

export default MiscellaneousBanner;