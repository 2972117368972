import React from "react";
import Banner from "../Banner";

function AcademicBanner(){
    return (
        <div>
        <Banner 
            bannertext="ACADEMICS" 
        />
        </div>
    );
}

export default AcademicBanner;