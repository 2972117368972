import React from "react";
import Nav_second from "../Nav-second";
import Class10Banner from "./Class10Banner";
import Class10 from "../Academics/Class10";
import Footer from "../Footer";

function Class_10(){
    return(
        <div>
            <Nav_second />
            <Class10Banner />
            <Class10 />
            <Footer />
        </div>
    );
}

export default Class_10;