import React from "react";
import Banner from "../Banner";

function CourseBanner(){
    return (
        <div>
        <Banner 
            bannertext="COURSES" 
        />
        </div>
    );
}

export default CourseBanner;