import React from "react";
import Banner from "../Banner";

function Class12Banner(){
    return (
        <div>
        <Banner 
            bannertext="CLASS-12" 
        />
        </div>
    );
}

export default Class12Banner;