import React from "react";
import Nav_second from "../Nav-second";
import Class12Banner from "./Class12Banner";
import Class12 from "../Academics/Class12";
import Footer from "../Footer";

function Class_12(){
    return(
        <div>
            <Nav_second />
            <Class12Banner />
            <Class12 />
            <Footer />
        </div>
    );
}

export default Class_12;